import store from "@/state/store";

export default [
  {
    path: "/",
    name: "index",
    redirect: "/dashboard",
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "dashboard" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboards/default"),
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/dashboard/saas",
    name: "saas-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/saas/index"),
  },
  {
    path: "/dashboard/crypto",
    name: "crypto-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/crypto/index"),
  },
  {
    path: "/dashboard/blog",
    name: "blog-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/blog/index"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { authRequired: true },
    component: () => import("./views/calendar/calendar"),
  },
  {
    path: "/campagnes/displayLite",
    name: "displayLite",
    meta: { authRequired: true },
    component: () => import("./views/campagnes/displayLite"),
  },
  {
    path: "/games/quizCategorie",
    name: "categorieQuiz",
    meta: { authRequired: true },
    component: () => import("./views/games/categorieQuiz"),
  },

  {
    path: "/games/addQuiz/:id",
    name: "addQuiz",
    meta: { authRequired: true },
    component: () => import("./views/games/addQuiz"),
  },
  {
    path: "/games/quiz/:id",
    name: "addQuestionQuiz",
    meta: { authRequired: true },
    component: () => import("./views/games/addQuestionQuiz"),
  },
  {
    path: "/games/addAnswerQuestion/:id",
    name: "addAnswerQuestion",
    meta: { authRequired: true },
    component: () => import("./views/games/addAnswerQuestion"),
  },
  {
    path: "/games/usersParticipationInQuiz/:id",
    name: "usersParticipationInQuiz",
    meta: { authRequired: true },
    component: () => import("./views/games/usersParticipationInQuiz"),
  },
  {
    path: "/videos",
    name: "video",
    meta: { authRequired: true },
    component: () => import("./views/video/video.vue"),
  },
  {
    path: "/videos/overview/:uiid",
    name: "overviewVideo",
    meta: { authRequired: true },
    component: () => import("./views/video/overview.vue"),
  },
  {
    path: "/programmes",
    name: "programme",
    meta: { authRequired: true },
    component: () => import("./views/programme/programs.vue"),
  },
  {
    path: "/programmes/overview/:uiid",
    name: "overviewProgramme",
    meta: { authRequired: true },
    component: () => import("./views/programme/overviewProgram.vue"),
  },
  {
    path: "/sections",
    name: "sections",
    meta: { authRequired: true },
    component: () => import("./views/section/sections.vue"),
  },
  {
    path: "/sections/sectionVideos",
    name: "sectionsVideos",
    meta: { authRequired: true },
    component: () => import("./views/section/sectionVideos.vue"),
  },
  {
    path: "/sections/sectionPrograms",
    name: "sectionPrograms",
    meta: { authRequired: true },
    component: () => import("./views/section/sectionPrograms.vue"),
  },
  {
    path: "/sections/sectionImages",
    name: "sectionImages",
    meta: { authRequired: true },
    component: () => import("./views/section/sectionImages.vue"),
  },
  {
    path: "/tags",
    name: "tags",
    meta: { authRequired: true },
    component: () => import("./views/tags/tags.vue"),
  },
  {
    path: "/categories",
    name: "categories",
    meta: { authRequired: true },
    component: () => import("./views/categories/categories.vue"),
  },
  {
    path: "/chat",
    name: "chat",
    meta: { authRequired: true },
    component: () => import("./views/chat/index"),
  },
  {
    path: "/apps/file-manager",
    name: "file-manager",
    meta: { authRequired: true },
    component: () => import("./views/file-manager/index"),
  },
  {
    path: "/ecommerce/products",
    name: "Products",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail/:id",
    name: "Product Detail",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/product-detail"),
  },
  {
    path: "/ecommerce/orders",
    name: "Orders",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/orders"),
  },
  {
    path: "/ecommerce/customers",
    name: "Customers",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/customers"),
  },
  {
    path: "/ecommerce/cart",
    name: "Cart",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/cart"),
  },
  {
    path: "/ecommerce/checkout",
    name: "Checkout",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/checkout"),
  },
  {
    path: "/ecommerce/shops",
    name: "Shops",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/shops"),
  },
  {
    path: "/ecommerce/add-product",
    name: "Add Product",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/add-product"),
  },
  {
    path: "/award/awardList",
    name: "award-list",
    meta: { authRequired: true },
    component: () => import("./views/award/awardList"),
  },
  {
    path: "/award/candidateList/:id",
    name: "award-candidateList",
    meta: { authRequired: true },
    component: () => import("./views/award/candidateList"),
  },
  {
    path: "/award/awardDetail/:id",
    name: "award-detail",
    meta: { authRequired: true },
    component: () => import("./views/award/awardDetail"),
  },
  {
    path: "/award/awardCandidateCategorie/:id/:rang",
    name: "award-awardCandidateCategorie",
    meta: { authRequired: true },
    component: () => import("./views/award/awardCandidateCategorie"),
  },
  {
    path: "/award/transactionInAward/:id/:awardName",
    name: "award-transactionInAward",
    meta: { authRequired: true },
    component: () => import("./views/award/transactionInAward"),
  },
  {
    path: "/award/regulationAward/:id/:awardName",
    name: "award-regulationAward",
    meta: { authRequired: true },
    component: () => import("./views/award/regulationAward"),
  },
  {
    path: "/award/voteListInAward/:id/:awardName",
    name: "award-voteListInAward",
    meta: { authRequired: true },
    component: () => import("./views/award/voteListInAward"),
  },
  {
    path: "/blog/list",
    name: "blog-list",
    meta: { authRequired: true },
    component: () => import("./views/blog/list"),
  },
  {
    path: "/blog/tagsArticle",
    name: "blog-Tags",
    meta: { authRequired: true },
    component: () => import("./views/blog/tagsArticle"),
  },
  {
    path: "/blog/overviewCategorie/:id",
    name: "blog-overviewCategorie",
    meta: { authRequired: true },
    component: () => import("./views/blog/overviewCategorie"),
  },
  {
    path: "/blog/categArticle",
    name: "blog-categories",
    meta: { authRequired: true },
    component: () => import("./views/blog/categArticle"),
  },
  {
    path: "/blog/createArticle",
    name: "create-article",
    meta: { authRequired: true },
    component: () => import("./views/blog/createArticle"),
  },
  {
    path: "/blog/grid",
    name: "blog-grid",
    meta: { authRequired: true },
    component: () => import("./views/blog/grid"),
  },
  {
    path: "/blog/detail/:id",
    name: "blog-detail",
    meta: { authRequired: true },
    component: () => import("./views/blog/detail"),
  },
  {
    path: "/blog/updateArticle/:id",
    name: "blog-updateArticle",
    meta: { authRequired: true },
    component: () => import("./views/blog/updateArticle"),
  },
  {
    path: "/crypto/wallet",
    name: "Wallet",
    meta: { authRequired: true },
    component: () => import("./views/crypto/wallet/index"),
  },
  {
    path: "/crypto/buy-sell",
    name: "Buy/sell",
    meta: { authRequired: true },
    component: () => import("./views/crypto/buysell/index"),
  },
  {
    path: "/crypto/exchange",
    name: "Exchange",
    meta: { authRequired: true },
    component: () => import("./views/crypto/exchange/index"),
  },
  {
    path: "/crypto/lending",
    name: "Lending",
    meta: { authRequired: true },
    component: () => import("./views/crypto/lending/index"),
  },
  {
    path: "/crypto/orders",
    name: "crypto-orders",
    meta: { authRequired: true },
    component: () => import("./views/crypto/orders/index"),
  },
  {
    path: "/crypto/kyc-application",
    name: "kyc-application",
    meta: { authRequired: true },
    component: () => import("./views/crypto/kycapplication/index"),
  },
  {
    path: "/crypto/ico-landing",
    name: "Ico-landing",
    meta: { authRequired: true },
    component: () => import("./views/crypto/ico-landing"),
  },
  {
    path: "/invoices/detail",
    name: "Invoice Detail",
    meta: { authRequired: true },
    component: () => import("./views/invoices/detail"),
  },
  {
    path: "/invoices/list",
    name: "Invoice List",
    meta: { authRequired: true },
    component: () => import("./views/invoices/list"),
  },
  {
    path: "/ui/alerts",
    name: "Alerts",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "Buttons",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "Cards",
    meta: { authRequired: true },
    component: () => import("./views/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "Carousel",
    meta: { authRequired: true },
    component: () => import("./views/ui/carousel"),
  },
  {
    path: "/ui/dropdowns",
    name: "Dropdowns",
    meta: { authRequired: true },
    component: () => import("./views/ui/dropdowns"),
  },
  {
    path: "/ui/grid",
    name: "Grid",
    meta: { authRequired: true },
    component: () => import("./views/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "Images",
    meta: { authRequired: true },
    component: () => import("./views/ui/images"),
  },
  {
    path: "/ui/modals",
    name: "Modals",
    meta: { authRequired: true },
    component: () => import("./views/ui/modals"),
  },
  {
    path: "/ui/offcanvas",
    name: "offcanvas",
    meta: { authRequired: true },
    component: () => import("./views/ui/offcanvas"),
  },
  {
    path: "/ui/rangeslider",
    name: "Rangeslider",
    meta: { authRequired: true },
    component: () => import("./views/ui/rangeslider"),
  },
  {
    path: "/ui/progressbars",
    name: "Progressbars",
    meta: { authRequired: true },
    component: () => import("./views/ui/progressbars"),
  },
  {
    path: "/ui/placeholder",
    name: "Placeholder",
    meta: { authRequired: true },
    component: () => import("./views/ui/placeholder"),
  },
  {
    path: "/ui/sweet-alert",
    name: "Sweet-alert",
    meta: { authRequired: true },
    component: () => import("./views/ui/sweet-alert"),
  },
  {
    path: "/ui/tabs-accordions",
    name: "Tabs-accordions",
    meta: { authRequired: true },
    component: () => import("./views/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "Typography",
    meta: { authRequired: true },
    component: () => import("./views/ui/typography"),
  },
  {
    path: "/ui/video",
    name: "Video",
    meta: { authRequired: true },
    component: () => import("./views/ui/video"),
  },
  {
    path: "/ui/general",
    name: "General",
    meta: { authRequired: true },
    component: () => import("./views/ui/general"),
  },
  {
    path: "/ui/colors",
    name: "Colors",
    meta: { authRequired: true },
    component: () => import("./views/ui/colors"),
  },
  {
    path: "/ui/lightbox",
    name: "Lightbox",
    meta: { authRequired: true },
    component: () => import("./views/ui/lightbox"),
  },
  {
    path: "/ui/image-cropper",
    name: "Image Cropper",
    meta: { authRequired: true },
    component: () => import("./views/ui/cropper"),
  },
  {
    path: "/projects/overview/:id",
    name: "Project Overview",
    meta: { authRequired: true },
    component: () => import("./views/projects/overview"),
  },
  {
    path: "/projects/overviewCategorie/:id",
    name: "Project OverviewCategorie",
    meta: { authRequired: true },
    component: () => import("./views/projects/overviewCategorie"),
  },
  {
    path: "/projects/updateChannel/:id",
    name: "Update Channel",
    meta: { authRequired: true },
    component: () => import("./views/projects/updateChannel"),
  },
  {
    path: "/projects/videos",
    name: "Videos-list",
    meta: { authRequired: true },
    component: () => import("./views/projects/videos"),
  },
  {
    path: "/projects/updateVideos/:id",
    name: "updateVideos view",
    meta: { authRequired: true },
    component: () => import("./views/projects/updateVideos"),
  },
  {
    path: "/projects/banner",
    name: "banner view",
    meta: { authRequired: true },
    component: () => import("./views/projects/banner"),
  },
  {
    path: "/projects/categories",
    name: "categories view",
    meta: { authRequired: true },
    component: () => import("./views/projects/categories"),
  },
  {
    path: "/projects/updateCategories/:id",
    name: "updateCategories view",
    meta: { authRequired: true },
    component: () => import("./views/projects/updateCategories"),
  },
  {
    path: "/projects/programmes",
    name: "ProgrammesView",
    meta: { authRequired: true },
    component: () => import("./views/projects/programmes"),
  },
  {
    path: "/projects/programmeDetails/:id",
    name: "programmeDetails View",
    meta: { authRequired: true },
    component: () => import("./views/projects/programmeDetails"),
  },
  {
    path: "/projects/slide",
    name: "Slide View",
    meta: { authRequired: true },
    component: () => import("./views/projects/slide"),
  },
  {
    path: "/projects/tags",
    name: "tags View",
    meta: { authRequired: true },
    component: () => import("./views/projects/tags"),
  },
  {
    path: "/projects/link",
    name: "Links View",
    meta: { authRequired: true },
    component: () => import("./views/projects/link"),
  },
  {
    path: "/projects/liveFlux",
    name: "live Flux View",
    meta: { authRequired: true },
    component: () => import("./views/projects/liveFlux"),
  },
  {
    path: "/projects/images",
    name: "Images View",
    meta: { authRequired: true },
    component: () => import("./views/projects/images"),
  },
  {
    path: "/projects/sections",
    name: "sections view",
    meta: { authRequired: true },
    component: () => import("./views/projects/sections"),
  },
  {
    path: "/projects/sectionProgramme",
    name: "sectionProgramme view",
    meta: { authRequired: true },
    component: () => import("./views/projects/sectionProgramme"),
  },
  {
    path: "/projects/channels",
    name: "channels view",
    meta: { authRequired: true },
    component: () => import("./views/projects/channels"),
  },
  {
    path: "/projects/channelDetails/:id",
    name: "channelDetails view",
    meta: { authRequired: true },
    component: () => import("./views/projects/channelDetails"),
  },
  {
    path: "/projects/updateProgramme:id",
    name: "Update Programme",
    meta: { authRequired: true },
    component: () => import("./views/projects/updateProgramme"),
  },
  {
    path: "/contacts/grid",
    name: "User Grid",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-grid"),
  },
  {
    path: "/contacts/list",
    name: "User List",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-list"),
  },
  {
    path: "/contacts/profile",
    name: "Profile",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-profile"),
  },
  {
    path: "/contacts/updateProfile/:id",
    name: "Update Profile",
    meta: { authRequired: true },
    component: () => import("./views/contacts/update-profile"),
  },
  {
    path: "/charts/apex",
    name: "Apex chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/apex"),
  },
  {
    path: "/charts/chartjs",
    name: "Chartjs chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/chartjs/index"),
  },
  {
    path: "/charts/chartist",
    name: "Chartist chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/chartist"),
  },
  {
    path: "/charts/echart",
    name: "Echart chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/echart/index"),
  },
  {
    path: "/icons/boxicons",
    name: "Boxicons Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/boxicons"),
  },
  {
    path: "/icons/materialdesign",
    name: "Materialdesign Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/materialdesign"),
  },
  {
    path: "/icons/dripicons",
    name: "Dripicons Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/dripicons"),
  },
  {
    path: "/icons/fontawesome",
    name: "Fontawesome Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/fontawesome"),
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: { authRequired: true },
    component: () => import("./views/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { authRequired: true },
    component: () => import("./views/maps/leaflet/index"),
  },
  {
    path: "/tables/basic",
    name: "Basic Tables",
    meta: { authRequired: true },
    component: () => import("./views/tables/basictable"),
  },
  {
    path: "/form/advanced",
    name: "Form Advanced",
    meta: { authRequired: true },
    component: () => import("./views/forms/advanced"),
  },
  {
    path: "/form/elements",
    name: "Form Elements",
    meta: { authRequired: true },
    component: () => import("./views/forms/elements"),
  },
  {
    path: "/form/layouts",
    name: "Form Layouts",
    meta: { authRequired: true },
    component: () => import("./views/forms/layouts"),
  },
  {
    path: "/form/editor",
    name: "Form Editors",
    meta: { authRequired: true },
    component: () => import("./views/forms/editors"),
  },
  {
    path: "/form/uploads",
    name: "File Uploads",
    meta: { authRequired: true },
    component: () => import("./views/forms/uploads"),
  },
  {
    path: "/form/validation",
    name: "Form Validation",
    meta: { authRequired: true },
    component: () => import("./views/forms/validation"),
  },
  {
    path: "/form/wizard",
    name: "Form Wizard",
    meta: { authRequired: true },
    component: () => import("./views/forms/wizard"),
  },
  {
    path: "/form/repeater",
    name: "Form Repeater",
    meta: { authRequired: true },
    component: () => import("./views/forms/repeater"),
  },
  {
    path: "/form/mask",
    name: "Form Mask",
    meta: { authRequired: true },
    component: () => import("./views/forms/mask"),
  },
  {
    path: "/pages/starter",
    name: "Starter",
    meta: { authRequired: true },
    component: () => import("./views/utility/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { authRequired: true },
    component: () => import("./views/utility/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { authRequired: true },
    component: () => import("./views/utility/coming-soon"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    meta: { authRequired: true },
    component: () => import("./views/utility/timeline"),
  },
  {
    path: "/pages/faqs",
    name: "FAQs",
    meta: { authRequired: true },
    component: () => import("./views/utility/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    meta: { authRequired: true },
    component: () => import("./views/utility/pricing"),
  },
  {
    path: "/pages/404",
    name: "Error-404",
    meta: { authRequired: true },
    component: () => import("./views/utility/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: { authRequired: true },
    component: () => import("./views/utility/500"),
  },
  {
    path: "/email/inbox",
    name: "Inbox",
    meta: { authRequired: true },
    component: () => import("./views/email/inbox"),
  },
  {
    path: "/email/reademail/:id",
    name: "Read Email",
    meta: { authRequired: true },
    component: () => import("./views/email/reademail"),
  },
  {
    path: "/email/templates/basic",
    name: "Email template basic",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/basic"),
  },
  {
    path: "/email/templates/billing",
    name: "Email template billing",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/billing"),
  },
  {
    path: "/email/templates/alert",
    name: "Email template alert",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/alert"),
  },
  {
    path: "/tasks/list",
    name: "Task list",
    meta: { authRequired: true },
    component: () => import("./views/tasks/task-list"),
  },
  {
    path: "/tasks/kanban",
    name: "Kanbanboard",
    meta: { authRequired: true },
    component: () => import("./views/tasks/kanbanboard"),
  },
  {
    path: "/tasks/create",
    name: "Create Task",
    meta: { authRequired: true },
    component: () => import("./views/tasks/task-create"),
  },
  {
    path: "/auth/login-1",
    name: "Login sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/login-sample"),
  },
  {
    path: "/auth/login-2",
    name: "Login-2-sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/login-2"),
  },
  {
    path: "/auth/register-1",
    name: "Register sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/register-sample"),
  },
  {
    path: "/auth/register-2",
    name: "Register-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/register-2"),
  },
  {
    path: "/auth/recoverpwd",
    name: "Recover pwd",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/recoverpw-sample"),
  },
  {
    path: "/auth/recoverpwd-2",
    name: "Recover pwd-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/recoverpwd-2"),
  },
  {
    path: "/auth/lock-screen",
    name: "Lock screen",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/lockscreen"),
  },
  {
    path: "/auth/lock-screen-2",
    name: "Lock screen-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/lockscreen-2"),
  },
  {
    path: "/auth/confirm-mail",
    name: "confirm-mail",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/confirm-mail"),
  },
  {
    path: "/auth/confirm-mail-2",
    name: "confirm-mail-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/confirm-mail-2"),
  },
  {
    path: "/auth/email-verification",
    name: "email-verification",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/email-verification"),
  },
  {
    path: "/auth/email-verification-2",
    name: "email-verification-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/email-verification-2"),
  },
  {
    path: "/auth/two-step-verification",
    name: "two-step-verification",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/two-step-verification"),
  },
  {
    path: "/auth/two-step-verification-2",
    name: "two-step-verification-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/two-step-verification-2"),
  },
];
